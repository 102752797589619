/* eslint-disable linebreak-style */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { AddMoreTable, AuthErrorCard, FormInput } from "components";
import classnames from "classnames";
import { _notifyError, _validateForm, isObjectEmpty, isStringEmpty } from "utils";
import * as actionTypes from "store/types/utilityTypes";
import { useDispatch, useSelector } from "react-redux";
import { addSupplier, updateSelectedSupplier } from "store/actions";
import { Button } from "components/ui/buttons/button";
import { TableCell, TableRow } from "components/ui/table";

export const SaveSupplier = ({ entity, toggle }) => {
  const dispatch = useDispatch();
  const { suppliers } = useSelector((store) => store);
  const { isSaving, formErrorMessage, items } = suppliers;

  const initialItem = {};

  const itemDetails = entity._id
    ? {
        ...entity,
      }
    : initialItem;

  const [state, setState] = useState({
    form: itemDetails,
    errorState: {},
    supplier: [],
  });

  const { form, supplier, errorState } = state;

  const handleAttributeChange = (value, attribute, countryCode = {}) => {
    const formStatus = _validateForm(value, attribute, form, errorState, countryCode?.dialCode);
    setState({
      ...state,
      form: formStatus.obj,
      errorState: formStatus.errorState,
    });
  };

  const isDisabled = () => {
    let status;

    const identical = supplier.find((item) => String(item.name).trim() === String(form.name).trim()) === undefined;
    const multi = supplier.length > 0;
    const isFormEmpty = isStringEmpty(form.name) && isStringEmpty(form.phoneNumber) && !errorState.email;
    const nonEmptyFormComparism =
      !errorState.name &&
      !isStringEmpty(form.name) &&
      !errorState.phoneNumber &&
      !isStringEmpty(form.phoneNumber) &&
      !errorState.email &&
      !isStringEmpty(form.email);

    let condition;

    if (multi) {
      condition = isFormEmpty ? true : nonEmptyFormComparism && identical;
    } else {
      condition = nonEmptyFormComparism;
    }

    condition ? (status = true) : (status = false);
    return status;
  };

  const add = (newSupplier) => {
    let payload = Object.entries(newSupplier).map(([, value]) => value);
    if (!isObjectEmpty(form)) {
      payload.push(form);
    }

    const error = items.find((item) => {
      return [...payload].find(
        (load) =>
          load.name.toLowerCase() === item.name.toLowerCase() ||
          load.email.toLowerCase() === (item.email || "").toLowerCase(),
      );
    });
    if (error) {
      _notifyError(`Error: Supplier "${error.name}" with email "${error.email}" already exists.`);
      return;
    }

    dispatch(addSupplier(payload, items || [], toggle));
  };

  const handleUpdate = (selected, type) => {
    const payload = { name: selected.name, values: selected.values };
    const updatedItem = { ...selected, ...payload };
    // const toggle_ = toggleViewModal ? toggleViewModal : toggle;

    const error = items.find(
      (item) =>
        item.name.toLowerCase() !== entity.name.toLowerCase() &&
        (item.name.toLowerCase() === selected.name.toLowerCase() ||
          item.email.toLowerCase() === selected.email.toLowerCase()),
    );

    if (error) {
      _notifyError(`Error: Supplier "${error.name}" already exists.`);
      return;
    }

    if (type === "edit") {
      dispatch(updateSelectedSupplier(selected._id, payload, updatedItem, toggle, "updated"));
    }
  };

  const onClose = () => dispatch({ type: actionTypes.CLOSE_FORM_ERROR });

  const _onSubmit = (payload) => {
    if (entity._id) {
      handleUpdate(form, "edit");
    } else {
      add(payload);
    }
  };

  const addMoreCondition = () => {
    let status;

    const identical = supplier.find((item) => String(item.name).trim() === String(form.name).trim()) === undefined;
    const isFormEmpty = isStringEmpty(form.name) && isStringEmpty(form.phoneNumber) && !errorState.email;
    const nonEmptyFormComparism =
      !errorState.name &&
      !isStringEmpty(form.name) &&
      !errorState.phoneNumber &&
      !isStringEmpty(form.phoneNumber) &&
      !errorState.email &&
      !isStringEmpty(form.email);

    let condition;

    if (isFormEmpty) {
      condition = false;
    } else {
      condition = nonEmptyFormComparism && identical;
    }

    condition ? (status = true) : (status = false);
    return status;
  };

  const unSelectSupplier = (selected) => {
    const updatedSuppliers = supplier.filter((test) => test.name !== selected);
    setState((prevState) => ({
      ...prevState,
      supplier: updatedSuppliers,
    }));
  };

  return (
    <>
      <div className="container-fluid">
        {formErrorMessage && <AuthErrorCard error={formErrorMessage} onClose={onClose} />}
        <form onSubmit={(e) => e.preventDefault()} className="form__settings form">
          <div className="tw-grid tw-grid-cols-12 tw-gap-6">
            <div className="tw-col-span-12 md:tw-col-span-6">
              <FormInput
                id="name"
                label="Supplier name"
                isRequired={true}
                placeholder="Enter supplier name"
                className={errorState.name ? "is-invalid tw-mb-0" : "tw-mb-0"}
                type="text"
                value={form.name}
                onChange={(e) => handleAttributeChange(e.target.value, "name")}
              />
            </div>

            <div className="tw-col-span-12 md:tw-col-span-6">
              <FormInput
                id="phone"
                label="Phone Number"
                className={errorState.phoneNumber ? "is-invalid tw-mb-0" : "tw-mb-0"}
                isRequired={true}
                placeholder="e.g 8111111111"
                type="tel"
                value={form.phoneNumber}
                onChange={(e, data) => handleAttributeChange(e, "phoneNumber", data)}
                error={errorState.phoneNumber && errorState.phoneNumber_error}
              />
            </div>

            <div className="tw-col-span-12">
              <FormInput
                id="email"
                label="Email"
                className={errorState.email ? "is-invalid tw-mb-0" : "tw-mb-0"}
                isRequired={true}
                placeholder="Enter email address"
                type="text"
                value={form.email}
                onChange={(e) => handleAttributeChange(e.target.value, "email")}
              />
            </div>
          </div>

          {!entity._id && (
            <div className="tw-my-10">
              <Button
                variant="link"
                className="tw-text-primary-600 tw-font-semibold tw-text-[14px] tw-px-0"
                onClick={() =>
                  addMoreCondition() &&
                  setState((prevState) => ({
                    ...prevState,
                    supplier: [...supplier, form],
                    errorState: {},
                    form: {},
                  }))
                }
              >
                <span className="tw-mr-3">
                  <i className="fas fa-plus" />
                </span>
                Add Another Supplier
              </Button>
            </div>
          )}

          {supplier.length > 0 && (
            <AddMoreTable heading={["Supplier", "Phone", "Email", ""]}>
              {supplier.map((individual) => {
                return (
                  <TableRow key={individual.name}>
                    <TableCell className="tw-pr-5 tw-pt-3">{individual.name}</TableCell>
                    <TableCell className="tw-pr-4 tw-pt-3">{individual.phoneNumber}</TableCell>
                    <TableCell className="tw-pr-4 tw-pt-3">{individual.email}</TableCell>
                    <TableCell className="tw-pr-4 tw-pt-3" onClick={() => unSelectSupplier(individual.name)}>
                      <i className="far fa-trash-alt"></i>
                    </TableCell>
                  </TableRow>
                );
              })}
            </AddMoreTable>
          )}

          <div className="tw-flex tw-space-x-5  tw-mt-14">
            <Button variant="primary" modifier="outline" onClick={toggle} className="btn__discard">
              Discard
            </Button>
            <Button
              variant="primary"
              disabled={!isDisabled()}
              className={classnames("btn__secondary", {})}
              loading={isSaving}
              onClick={() => _onSubmit(supplier)}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

SaveSupplier.propTypes = {
  entity: PropTypes.object,
  toggle: PropTypes.func,
};
