import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, getSerializedParams, printError } from "utils";
import { closeAllModals } from "store/features/modalSlice";

export const formApi = createApi({
  reducerPath: "formApi",
  baseQuery,
  tagTypes: ["Forms"],
  endpoints: (builder) => ({
    getForms: builder.query({
      query: (queryParams) => {
        const query = getSerializedParams(queryParams, true);
        return {
          url: `/forms${query ? `?${query}` : ""}`,
        };
      },
      transformResponse: (response) => response.data,
      providesTags: ["Forms"],
    }),
    getForm: builder.query({
      query: ({ _id }) => ({
        url: `/forms/${_id}`,
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Forms"],
    }),
    getDataSource: builder.query({
      query: () => `/forms/datasources`,
      transformResponse: (response) => response.data,
      providesTags: [],
    }),
    editForm: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/forms/${_id}`,
        method: "PATCH",
        body: { ...rest },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          const errorMessage = printError(err);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Forms"],
    }),
    createForm: builder.mutation({
      query: (payload) => ({
        url: `/forms`,
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          const errorMessage = printError(err?.error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Forms"],
    }),
  }),
});

export const { useGetFormsQuery, useGetDataSourceQuery, useGetFormQuery, useEditFormMutation, useCreateFormMutation } =
  formApi;
